<template>
  <v-dialog v-model="show" max-width="650" :fullscreen="$vuetify.breakpoint.mobile">
    <v-card v-if="currentItem">
      <v-row no-gutters>
        <v-card-title>{{ isEditing ? 'Edit price list' : 'Add new price list' }}</v-card-title>
        <v-btn depressed v-if="$vuetify.breakpoint.mobile" class="ml-auto mr-4 mt-4" icon @click="show = false">
          <v-icon dark>mdi-close-thick</v-icon>
        </v-btn>
      </v-row>
      <v-card-text>
        <v-form v-model="isValid" ref="editItemRef">
          <v-row>
            <v-col cols="12">
              <v-text-field :rules="[rules.required]" v-model="currentItem.name" dense label="Name"></v-text-field>
            </v-col>
          </v-row>

          <h3 class="mb-4">Price factors</h3>
          <v-row v-for="(item, index) in currentItem.values" :key="index">
            <v-col cols="auto">
              <h2>{{ item.currencyCodeName }}</h2>
            </v-col>
            <v-col>
              <v-text-field :rules="[rules.required, rules.decimal]" hide-details="auto" v-model="item.priceFactor" dense label="Price Factor"></v-text-field>
            </v-col>
            <v-col cols="auto">
              <v-btn width="100" @click="deleteCode(index)" :disabled="isInUse(index)" depressed color="error">{{ isInUse(index) ? 'In use' : 'Remove' }}</v-btn>
            </v-col>
          </v-row>

          <v-row align="center">
            <v-col>
              <v-select v-model="selectedCode" return-object :items="pendingCodes" label="Currency code" item-text="name" hide-details></v-select>
            </v-col>
            <v-col cols="auto">
              <v-btn @click="addPendingCode" :disabled="!selectedCode" depressed>Add price factor</v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions class="d-block d-md-flex">
        <v-btn depressed :block="$vuetify.breakpoint.mobile" @click="show = false" class="my-4">Cancel</v-btn>
        <v-btn
          depressed
          :block="$vuetify.breakpoint.mobile"
          @click="saveCurrent"
          color="success"
          class="ml-0 ml-md-4 my-4"
          :loading="isSaving"
          :disabled="!isValid"
        >
          <v-icon left>mdi-check</v-icon>
          {{ isEditing ? 'Update list' : 'Add list' }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import _ from 'lodash';
import apiService from '@/services/apiService.js';

export default {
  name: 'CreateEditPriceList',
  data: () => ({
    show: false,
    currentItem: {},
    selectedCode: null,
    codes: [],
    isSaving: false,
    isValid: null,
    rules: {
      required: (value) => !!value || 'Field cannot be empty.',
      decimal: (v) => /^-?(\d+(\.?\d*)?)$/.test(v) || 'Number is not valid. (##.####) ',
    },
  }),
  computed: {
    isEditing() {
      return this.currentItem?.id ? true : false;
    },
    pendingCodes() {
      return this.codes.filter((x) => !this.currentItem?.values?.some((v) => v.currencyCodeId === x.id));
    },
  },
  methods: {
    async loadCodes() {
      this.codes = await apiService.getCurrencyCodes();
    },
    addItem() {
      this.loadCodes();
      this.show = true;
      this.currentItem = {
        name: null,
        values: [],
      };
    },
    editItem(item) {
      this.loadCodes();
      this.show = true;
      this.currentItem = _.cloneDeep(item);
    },
    async saveCurrent() {
      this.isSaving = true;
      await apiService
        .updatePriceList(this.currentItem)
        .then(() => {
          this.show = false;
          this.$emit('item-updated');
        })
        .finally(() => {
          this.isSaving = false;
        });
    },
    addPendingCode() {
      this.currentItem.values.push({
        currencyCodeId: this.selectedCode.id,
        currencyCodeName: this.selectedCode.name,
        priceFactor: 0,
      });
    },
    isInUse(codeIndex) {
      const id = this.currentItem.values[codeIndex]?.currencyCodeId;
      return id && this.currentItem.currencyCodesInUse.includes(id);
    },
    deleteCode(index) {
      if (this.isInUse(index)) {
        alert('Currency code cannot be deleted as it is in use by one or more licenses.');
      } else {
        this.currentItem.values.splice(index, 1);
      }
    }
  },
};
</script>
