<template>
  <div v-if="!isLoading">
    <v-row>
      <v-col cols="12" sm="auto">
        <h1 class="text-left">{{ currentItem.name }}</h1>
      </v-col>
      <v-col cols="12" sm="auto">
        <v-btn :block="$vuetify.breakpoint.mobile" depressed class="align-self-center mt-2 ml-md-4 mb-3 mb-md-0" @click="editItem">
          <v-icon left>mdi-pencil-outline</v-icon>
          Edit
        </v-btn>
        <v-btn :block="$vuetify.breakpoint.mobile" color="error" outlined depressed class="align-self-center mt-2 ml-md-4 mb-3 mb-md-0" @click="deleteItem">
          <v-icon left>mdi-delete</v-icon>
          Delete
        </v-btn>
      </v-col>
    </v-row>

    <v-simple-table dense class="my-4">
      <tbody>
        <tr>
          <td class="font-weight-bold">Id:</td>
          <td>{{ currentItem.id }}</td>
        </tr>
        <tr>
          <td class="font-weight-bold">Name:</td>
          <td>{{ currentItem.name }}</td>
        </tr>
        <tr>
          <td class="font-weight-bold">Last updated:</td>
          <td><last-updated-field :item="currentItem" /></td>
        </tr>
      </tbody>
    </v-simple-table>

    <v-row class="mt-8">
      <v-col cols="12" md="6">
        <h3>Price factors</h3>
        <v-data-table :headers="valueHeaders" :items="currentItem.values" v-bind="$util.dataTableAttributes()">
        </v-data-table>
      </v-col>
    </v-row>

    <create-edit-price-list ref="CreateEditRef" @item-updated="bubbleEmit" />
  </div>
</template>

<script>
import apiService from '@/services/apiService.js';
import CreateEditPriceList from '../Dialogs/CreateEditPriceList.vue';

export default {
  name: 'PriceListDetails',
  components: { CreateEditPriceList },
  data: () => ({
    currentItem: null,
    isLoading: false,
    valueHeaders: [
      {
        text: 'Currency code',
        value: 'currencyCodeName',
      },
      {
        text: 'Price factor',
        value: 'priceFactor',
      },
    ],
  }),
  methods: {
    async getItem() {
      this.isLoading = true;
      this.currentItem = await apiService.getPriceListById(this.$route.params.Id);
      this.isLoading = false;
    },
    editItem() {
      this.$refs.CreateEditRef.editItem(this.currentItem);
    },
    bubbleEmit() {
      this.getItem().then(() => {
        this.$emit('item-updated');
      });
    },
    async deleteItem() {
      if (confirm('Are you sure you want to delete this price list? This cannot be undone.')) {
        await apiService.deletePriceList(this.currentItem.id);
        this.$router.back();
      }
    },
  },
  created() {
    this.getItem();
  },
};
</script>